import useFilter, {
  paramsToPath,
  arrayToParams,
  getCategoryLinkAttributes,
} from "grandus-lib/hooks/useFilter";
import { useRouter } from "next/router";
import { get, map, without, isEmpty, find } from "lodash";

import { Tag } from "antd";
import { Fragment } from "react";
import { RESERVED_URI_PARTS } from "grandus-lib/constants/UrlConstants";

const CategorySelectedTitle = ({ title }) => {
  return <span style={{ marginRight: 10 }}>{title}:</span>;
};

const CategorySelected = ({ handleGetData }) => {
  const router = useRouter();
  const { filter } = useFilter({
    useDataFromRouter: true,
  });

  let selectedDefaultParameters = arrayToParams(
    get(router, "query.parameters", [])
  );

  const selectedData = get(filter, "selected", {});

  const removeFromFilter = (parameterGroupKey, parameterValue = false) => {
    if (parameterValue) {
      const removed = without(
        selectedDefaultParameters[parameterGroupKey],
        encodeURIComponent(parameterValue)
      );

      if (isEmpty(removed)) {
        delete selectedDefaultParameters[parameterGroupKey];
      } else {
        selectedDefaultParameters[parameterGroupKey] = removed;
      }
    } else {
      delete selectedDefaultParameters[parameterGroupKey];
    }

    var data = null;
    if (handleGetData) {
      data = handleGetData(router, selectedDefaultParameters);
    } else {
      data = getCategoryLinkAttributes(
        get(router, "query.category"),
        paramsToPath(selectedDefaultParameters),
        get(router, "query"),
        { toDelete: ["category", "parameters", "page"] }
      );
    }

    router.push(data.href, data.as);
  };

  return (
    <>
      {get(selectedData, "price") ? (
        <Fragment key={`category-selected-price`}>
          <CategorySelectedTitle
            title={get(find(RESERVED_URI_PARTS, ["hash", "price"]), "title")}
          />
          <SelectedVariant
            group={{
              type: "slider",
              urlTitle: get(
                find(RESERVED_URI_PARTS, ["hash", "price"]),
                "urlTitle"
              ),
              values: [
                { value: get(selectedData, "price.from.data.priceFormatted") },
                { value: get(selectedData, "price.to.data.priceFormatted") },
              ],
            }}
            index={1}
            removeFromFilter={removeFromFilter}
          />
        </Fragment>
      ) : (
        ""
      )}

      {map(RESERVED_URI_PARTS, (reserved, index) => {
        const data = get(find(selectedData, ["key", reserved.key]), "data", []);

        if (isEmpty(data)) {
          return "";
        }

        return (
          <Fragment key={`category-selected-${reserved?.key}-${index}`}>
            <CategorySelectedTitle title={reserved.title} />
            {map(data, (value, iterator) => {
              return (
                <Tag
                  key={`category-selected-${reserved?.key}-value-${iterator}`}
                  closable
                  onClose={(e) =>
                    removeFromFilter(
                      get(reserved, "urlTitle"),
                      get(
                        value,
                        "urlName",
                        get(
                          value,
                          "urlTitle",
                          get(value, "v2-value", get(value, "name"))
                        )
                      )
                    )
                  }
                >
                  {get(value, "v2-label")}
                </Tag>
              );
            })}
          </Fragment>
        );
      })}

      {map(get(selectedData, "parameters.data", []), (group, index) => {
        return (
          <Fragment
            key={`category-selected-${selectedData?.parameters?.key}-${group?.id}-${index}`}
          >
            <CategorySelectedTitle title={get(group, "name")} />
            <SelectedVariant
              group={group}
              index={index}
              removeFromFilter={removeFromFilter}
            />
          </Fragment>
        );
      })}
    </>
  );
};

const SelectedVariant = ({ group, index, removeFromFilter }) => {
  switch (get(group, "type")) {
    case "slider":
      return (
        <Tag
          key={`category-selected-${group?.urlTitle}-${index}`}
          closable
          onClose={(e) => removeFromFilter(get(group, "urlTitle"))}
        >
          <span>
            {get(group, "values[0].value")} - {get(group, "values[1].value")}
          </span>
        </Tag>
      );
    case "select":
    case "selectMultiple":
    case "radio":
    case "checkbox":
    case "dropdown":
    default:
      return map(get(group, "values", []), (value, iterator) => {
        return (
          <Tag
            key={`category-selected-${group?.urlTitle}-${get(
              value,
              "urlName"
            )}-${index}-${iterator}`}
            closable
            onClose={(e) =>
              removeFromFilter(get(group, "urlTitle"), get(value, "urlName"))
            }
          >
            {get(value, "value")}
          </Tag>
        );
      });
  }
};

export default CategorySelected;
