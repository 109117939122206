import Link from "next/link";
import { get } from "lodash";

import dynamic from "next/dynamic";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);
const Price = dynamic(() =>
  import("grandus-lib/components-atomic/price/Price")
);

import styles from "components/product/card/ProductPromotedCard.module.scss";

const ProductPromotedCard = ({
  name,
  urlTitle,
  storeStatus,
  finalPriceData,
  photo,
}) => {
  return (
    <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
      <a className={styles.productPromotedCard}>
        <Image photo={photo} size={"325x240"} type={"jpg"} usePlacehoder={true} />
        <div className={styles.content}>
          <span className={styles.name}>{name}</span>
          <div className={styles.bottomRow}>
            <Price priceData={finalPriceData} className={styles.price} />
            <span className={styles.button}>detail</span>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ProductPromotedCard;
