import get from "lodash/get";
import set from "lodash/set";
import {
  arrayToPath,
  getCategoryLinkAttributes,
  paramsToPath,
  queryToQueryString,
} from "grandus-lib/hooks/useFilter";
import { reqGetHeadersFront, reqGetHost } from "grandus-lib/utils";
import CategoryPage from "pages/kategoria/[category]/[[...parameters]]";
import Head from "next/head";

const DEFAULT_CATEGORY = "elektromobily";

const IndexPage = (props) => {
  const { meta } = props;
  const handleGetData = (router, newState) => {
    return getCategoryLinkAttributes(
      DEFAULT_CATEGORY,
      paramsToPath(newState),
      get(router, "query"),
      { toDelete: ["category", "parameters", "page"] }
    );
  };
  return (
    <>
      <Head>
        <link rel="canonical" href="/kategoria/elektromobily" />
      </Head>
      <CategoryPage
        {...props}
        handleGetData={handleGetData}
        meta={{
          ...meta,
          ...{
            options: {
              disableCanonical: true,
            },
          },
        }}
      />
    </>
  );
};

export async function getServerSideProps(context) {
  const category = DEFAULT_CATEGORY;
  const parameters = arrayToPath(get(context, "params.parameters", []));
  const uri = queryToQueryString(get(context, "query", {}), {});
  const url = `${reqGetHost(
    context?.req
  )}/api/pages/category/${category}?param=${encodeURIComponent(
    parameters
  )}&${uri}`;

  const data = await fetch(url, {
    headers: reqGetHeadersFront(context?.req, {
      forwardUrl: context?.resolvedUrl,
    }),
  }).then((result) => {
    return result.json();
  });

  return {
    props: data,
  };
}

export default IndexPage;
