import { get, isFunction } from "lodash";
import { useRouter } from "next/router";
import Link from "next/link";
import { getCategoryLinkAttributesFromRouter } from "grandus-lib/hooks/useFilter";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";

const CategoryOrderingElement = ({ text = "", newOrdering = "", handleLinkAttributes = null }) => {
  const router = useRouter();
  let attributes = null;
  if (isFunction(handleLinkAttributes)) {
    attributes = handleLinkAttributes(router, newOrdering);
  } else {
    attributes = getCategoryLinkAttributesFromRouter(router, {
      dataToChange: { orderBy: newOrdering },
      toDelete: ["category", "parameters", "page"],
    })
  }
  return (
    <Link
      {...attributes}
      scroll={false}
    >
      <a>{text}</a>
    </Link>
  );
};

const CategoryOrdering = ({ handleLinkAttributes }) => {
  const router = useRouter();
  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[
          get(
            router,
            "query.orderBy",
            process.env.NEXT_PUBLIC_PRODUCT_DEFAULT_ORDERING
          ),
        ]}
        style={{ fontSize: "0.9em" }}
      >
        <Menu.Item key="time-desc" icon={<ClockCircleOutlined />}>
          <CategoryOrderingElement
            text={"Od najnovšieho"}
            newOrdering={"time-desc"}
            handleLinkAttributes={handleLinkAttributes}
          />
        </Menu.Item>
        <Menu.Item key="price-desc" icon={<ArrowDownOutlined />}>
          <CategoryOrderingElement
            text={"Od najdrahšieho"}
            newOrdering={"price-desc"}
            handleLinkAttributes={handleLinkAttributes}
          />
        </Menu.Item>
        <Menu.Item key="price-asc" icon={<ArrowUpOutlined />}>
          <CategoryOrderingElement
            text={"Od najlacnejšieho"}
            newOrdering={"price-asc"}
            handleLinkAttributes={handleLinkAttributes}
          />
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CategoryOrdering;
