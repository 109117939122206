import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";
import { Button, Row, Col, Carousel } from "antd";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";

import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import styles from "components/category/CategoryPartials.module.scss";

export const Title = ({ title, subtitle }) => {
  if (!title) {
    return "";
  }
  return (
    <Col xs={24}>
      <h1 className={styles?.title}>
        {title}
        {subtitle ? <small> | {subtitle}</small> : ""}
      </h1>
    </Col>
  );
};

export const CategoryBanner = ({ banners }) => {
  if (isEmpty(banners)) {
    return "";
  }

  return (
    <Carousel
      {...{
        dots: true,
        arrows: true,
        infinite: true,
        draggable: true,
        autoplay: true,
        autoplaySpeed: 3000,
      }}
    >
      {map(banners, (banner, index) => {
        const { id, url, photo } = banner;
        const imageProps = {
          priority: index === 0 ? true : false,
        };
        const image = (
          <Image
            photo={photo}
            size={"1480x200"}
            type={"jpg"}
            useNextImage={true}
            imageProps={imageProps}
          />
        );
        if (!url) {
          return (
            <div
              key={`banners-category-${id}-${index}`}
              className={styles.coverPhoto}
            >
              {image}
            </div>
          );
        }

        return (
          <div key={`banners-category-${id}-${index}`}>
            <Link href={url}>
              <a className={styles.coverPhoto}>{image}</a>
            </Link>
          </div>
        );
      })}
    </Carousel>
  );
};

export const CoverPhoto = ({ photo }) => {
  if (!photo?.path) {
    return "";
  }
  return (
    <Col xs={24}>
      <div>
        <Image
          className={"image"}
          photo={photo}
          size={"1200x180__cropped"}
          type={"jpg"}
          useNextImage={true}
        />
      </div>
    </Col>
  );
};

export const Description = ({ description }) => {
  return (
    <Col xs={24}>
      <div
        className={styles?.description}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </Col>
  );
};

export const SubCategories = ({ subCategories }) => {
  if (isEmpty(subCategories)) {
    return "";
  }
  return (
    <Row gutter={[8, 15]}>
      {map(subCategories, (subCategory, index) => {
        const { id, name, urlName, mainPhoto = null } = subCategory;
        return (
          <Col
            key={`subcategory-${id}-${index}`}
            className={styles?.subcategories}
          >
            <Link {...getCategoryLinkAttributes(urlName)}>
              <Button
                icon={
                  <Image
                    photo={mainPhoto}
                    size={"18x18"}
                    type={"png"}
                    useNextImage={true}
                  />
                }
              >
                {name}
              </Button>
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};

const Parameters = ({}) => {
  return <span>a</span>;
};
