import { Typography, Pagination } from "antd";
import { useRouter } from "next/router";
import { get, parseInt, isFunction } from "lodash";
import { getCategoryLinkAttributesFromRouter } from "grandus-lib/hooks/useFilter";
import { scrollToTop } from "grandus-lib/utils";

const { Text } = Typography;

const PaginationComponent = ({
  totalCount = 0,
  pageCount = 1,
  currentPage = 0,
  perPage = 0,
  handleLinkAttributes = null
}) => {
  const router = useRouter();
  const defaultPageSize = process.env.NEXT_PUBLIC_PRODUCT_DEFAULT_PER_PAGE;

  if (!totalCount || !pageCount || !currentPage || !perPage) {
    return null;
  }

  return (
    <>
      <Pagination
        showLessItems={true}
        current={parseInt(get(router, "query.page", 1))}
        defaultCurrent={parseInt(get(router, "query.page", 1))}
        total={totalCount}
        defaultPageSize={defaultPageSize}
        pageSize={parseInt(get(router, "query.perPage", defaultPageSize))}
        showSizeChanger={totalCount > defaultPageSize}
        responsive={true}
        pageSizeOptions={[
          defaultPageSize,
          defaultPageSize * 1.5,
          defaultPageSize * 2.5,
        ]}
        hideOnSinglePage={false}
        onChange={(page, pageSize) => {
          let dataToChange = {};
          let toDelete = ["parameters", "category"];

          if (page > 1) {
            dataToChange.page = page;
          } else {
            toDelete.push("page");
          }

          if (pageSize != defaultPageSize) {
            dataToChange.perPage = pageSize;
          } else {
            toDelete.push("perPage");
          }

          let data = null;
          if (isFunction(handleLinkAttributes)) {
            data = handleLinkAttributes(router, {
              dataToChange,
              toDelete
            });
          } else {
            data = getCategoryLinkAttributesFromRouter(router, {
              dataToChange,
              toDelete,
            });
          }
          
          router.push(data.href, data.as).then(() => scrollToTop());
        }}
      />
      <div style={{ paddingTop: "10px", fontSize: "0.9em" }}>
        <Text type="secondary">
          zobrazených {currentPage > 1 ? (currentPage - 1) * perPage : 1} -{" "}
          {currentPage * perPage < totalCount && currentPage !== pageCount
            ? currentPage * perPage
            : totalCount}{" "}
          z celkového počtu {totalCount}
        </Text>
      </div>
    </>
  );
};

export default PaginationComponent;
