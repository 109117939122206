import { get, find, uniq, indexOf, remove, isArray, isEmpty } from "lodash";
import { useState } from "react";
import { useRouter } from "next/router";
import useFilter, {
  paramsToPath,
  arrayToParams,
  getCategoryLinkAttributes,
} from "grandus-lib/hooks/useFilter";
import { RESERVED_URI_PARTS } from "grandus-lib/constants/UrlConstants";

import styles from "./CategoryParameters.module.scss";

import dynamic from "next/dynamic";
const ElementCheckbox = dynamic(() =>
  import("./categoryElements/CategoryElementCheckbox")
);
const ElementRadio = dynamic(() =>
  import("./categoryElements/CategoryElementRadio")
);
const ElementSelect = dynamic(() =>
  import("./categoryElements/CategoryElementSelectbox")
);
const ElementSlider = dynamic(() =>
  import("./categoryElements/CategoryElementSlider")
);
const ElementPrice = dynamic(() =>
  import("./categoryElements/CategoryElementPrice")
);

const PARAMETER_SHOW_LIMIT = 5;

const CategoryParameters = ({ initialData, handleGetData }) => {
  const router = useRouter();
  const [openedParameter, setOpenedParameter] = useState([]);

  let selected = arrayToParams(get(router, "query.parameters", []));

  const setSelected = (newData) => {
    selected = newData;
  };

  const { filter, isLoading } = useFilter({
    useDataFromRouter: true,
    options: {
      initialData: initialData,
    },
  });

  const categoryData = filter;

  const onClickToggleOpen = (parameterId) => (e) => {
    if (indexOf(openedParameter, parameterId) >= 0) {
      setOpenedParameter(remove(openedParameter, parameterId));
    } else {
      setOpenedParameter(uniq([...openedParameter, parameterId]));
    }
  };

  if (!categoryData) {
    return null;
  }

  const handleChange = (paramKey, paramValues) => {
    const newState = { ...selected };
    delete newState[paramKey];
    if (!isEmpty(paramValues)) {
      newState[paramKey] = isArray(paramValues) ? paramValues : [paramValues];
    }

    setSelected(newState);

    var data = null;
    if (handleGetData) {
      data = handleGetData(router, newState);
    } else {
      data = getCategoryLinkAttributes(
        get(router, "query.category"),
        paramsToPath(newState),
        get(router, "query"),
        { toDelete: ["category", "parameters", "page"] }
      );
    }

    router.push(data.href, data.as);
  };

  const minPrice = get(
    categoryData,
    "selected.price.from.data.price",
    get(categoryData, "selectedPriceFrom", false)
  );
  const maxPrice = get(
    categoryData,
    "selected.price.to.data.price",
    get(categoryData, "selectedPriceTo", false)
  );

  return (
    <div className={`${styles.wrapper} ${isLoading ? styles.loading : ""}`}>
      <ElementPrice
        selected={{
          min: minPrice ? minPrice : get(categoryData, "minPrice"),
          max: maxPrice ? maxPrice : get(categoryData, "maxPrice"),
        }}
        handleChange={handleChange}
        options={{
          min: get(categoryData, "minPrice"),
          max: get(categoryData, "maxPrice"),
          styles,
        }}
      />
      
      <ElementSelect
        {...getSystemFilterAttributes(
          get(categoryData, "storeLocations", []),
          "storeLocation",
          {
            openedParameter,
            selected,
            onClickToggleOpen,
            handleChange,
          }
        )}
        attributes={{ showSearch: true }}
      />

      <ElementCheckbox
        {...getSystemFilterAttributes(
          get(categoryData, "brands", []),
          "brand",
          {
            openedParameter,
            selected,
            onClickToggleOpen,
            handleChange,
          }
        )}
      />

      <ElementCheckbox
        {...getSystemFilterAttributes(
          get(categoryData, "conditions", []),
          "condition",
          {
            openedParameter,
            selected,
            onClickToggleOpen,
            handleChange,
          }
        )}
      />

      <ElementCheckbox
        {...getSystemFilterAttributes(
          get(categoryData, "statuses", []),
          "status",
          {
            openedParameter,
            selected,
            onClickToggleOpen,
            handleChange,
          }
        )}
      />

      {get(categoryData, "parameters", []).map((parameter, index) => {
        const propsData = {
          key: `category-${get(parameter, "id")}-${index}`,
          parameter: parameter,
          selected: selected,
          handleChange: handleChange,
          options: {
            styles: styles,
            ...getShowMoreAttributes(
              parameter,
              openedParameter,
              onClickToggleOpen
            ),
          },
        };

        switch (parameter?.type) {
          case "select":
            return (
              <ElementSelect {...propsData} attributes={{ showSearch: true }} />
            );
          case "selectMultiple":
            return (
              <ElementSelect
                {...propsData}
                attributes={{
                  showSearch: false,
                  mode: "multiple",
                }}
              />
            );
          case "radio":
            return <ElementRadio {...propsData} />;
          case "slider":
            return <ElementSlider {...propsData} />;
          case "checkbox":
          case "dropdown": //fallback
          default:
            return <ElementCheckbox {...propsData} />;
        }
      })}
    </div>
  );
};

const getSystemFilterAttributes = (data, key, options = {}) => {
  return {
    parameter: {
      id: key,
      name: get(options, "name")
        ? get(options, "name")
        : get(find(RESERVED_URI_PARTS, ["key", key]), "title", key),
      urlTitle: get(find(RESERVED_URI_PARTS, ["key", key]), "urlTitle", key),
      values: data,
    },
    handleChange: get(options, "handleChange"),
    selected: get(options, "selected"),
    options: {
      styles: styles,
      ...getShowMoreAttributes(
        {
          id: key,
          values: data,
        },
        get(options, "openedParameter"),
        get(options, "onClickToggleOpen")
      ),
    },
  };
};

const getShowMoreAttributes = (parameter, opened, onClickToggleOpen) => {
  return {
    showMoreEnabled: get(parameter, "values", []).length > PARAMETER_SHOW_LIMIT,
    showMoreActive: !(indexOf(opened, get(parameter, "id")) >= 0),
    showMoreLimit: PARAMETER_SHOW_LIMIT,
    showMoreToggle: onClickToggleOpen,
  };
};

export default CategoryParameters;
