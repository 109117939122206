import { isEmpty, get } from "lodash";
import { Carousel } from "antd";

import ProductPromotedCard from "components/product/card/ProductPromotedCard";

import styles from "./CategoryPromotedProducts.module.scss";

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 7,
  slidesToScroll: 7,
  autoplaySpeed: 7000,
};

const CategoryPromotedProducts = ({ products, options = {} }) => {
  if (isEmpty(products)) {
    return null;
  }

  const carouselSettings = { ...settings, ...get(options, "settings", {}) };
  return (
    <div  className={styles?.wrapper}>
      <h2 className={styles?.headline}>Najobľúbenejšie produkty</h2>
      <section className={styles?.carouselWrapper}>
        <Carousel
          autoplay
          {...carouselSettings}
          className={styles?.promotedProducts}
        >
          {products.map((product) => (
            <ProductPromotedCard
              {...product}
              key={`promoted-product-${product?.id}`}
            />
          ))}
        </Carousel>
      </section>
    </div>
  );
};

export default CategoryPromotedProducts;
